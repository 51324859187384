<template>
    <a-row class="header-top">
        <a-col :span="8" class="header-top-logo">
            <img src="../assets/image/logo.png" style="width: 30%;" />
        </a-col>
        <a-col :span="14" class="header-top-menu">
            <a-space :size="20">
                <!-- <img src="@/assets/image/contact.png" style="width: 38px;" />
                <span style="font-size: 21px;font-weight: bolder;color: #333;">0375-2098789</span> -->
                <template v-for="(button, index) in ButtonGroup">
                    <a-space-item :class="currentIndex === index ? 'space-item-clicked' : 'space-item'">
                        <a-button type="link" shape="round" size="small"
                            :class="currentIndex === index ? 'menu-button-clicked' : 'menu-button'"
                            @click="(value) => onClick(button, index)">
                            {{ button.title }}
                        </a-button>
                    </a-space-item>
                </template>
            </a-space>
        </a-col>
        <a-col :span="2" class="header-top-menu">
            <template v-for="(button, index) in LoginItems">
                <a-button style="font-weight: bold; font-size: 16px; line-height: 1px;" :type="button.style"
                    :shape="button.shape" @click="(value) => onClick(button)">
                    {{ button.title }}
                </a-button>
            </template>
            <!-- <a-row justify="space-between">
                <a-col :span="10">
                    <phone-outlined style="font-size: 30px;" />
                    <span style="font-weight: bold; font-size: 17px;">0375-3883029</span>
                </a-col>
                <a-col :span="12">
                    <a-space :size="20">
                        <template v-for="(button, index) in LoginItems">
                            <a-button style="font-weight: bold;" :type="button.type" :shape="button.shape" @click="(value) => onClick(button)">
                                {{ button.title }}
                            </a-button>
                        </template>
                    </a-space>
                </a-col>
            </a-row> -->
        </a-col>
    </a-row>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router'
//先在setup中定义
const router = useRouter()
const currentRouter = ref('/')
const currentIndex = ref(0)
const ButtonGroup = [
    {
        title: '首页',
        type: 'index',
        path: '/',
        key: '/HomeContent'
    },
    // {
    //     title: '我找会计',
    //     type: 'index',
    //     path: 'FindUser',
    //     key: '/FindUser'
    // },
    // {
    //     title: '我找企业',
    //     type: 'index',
    //     path: 'FindCompany',
    //     key: '/FindCompany'
    // }
]
const LoginItems = [
    {
        title: '注册/登录',
        type: 'url',
        path: 'https://www.shangbian.net/v3',
        style: 'primary',
        shape: 'round'
    },
    // {
    //     title: '登录',
    //     type: 'url',
    //     path: 'https://www.shangbian.net/v3/',
    //     style: 'text',
    //     shape: 'default'
    // },
]

const onClick = (button, index) => {
    currentIndex.value = index
    currentRouter.value = button.key ? button.key : currentRouter.value
    if (button.type === 'url') {
        window.open(button.path, '_blank')
    } else if (button.type === 'jump') {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    } else {
        router.push(button.path)
    }

}

const getRouter = () => {
    console.log('router', router.currentRoute.value.fullPath);
    currentRouter.value = router.currentRoute.value.fullPath;
    const current = ButtonGroup.findIndex(item => item.key === currentRouter.value)
    currentIndex.value = current
}

onBeforeMount(() => {
    getRouter();
})
</script>

<style>
.header-top-logo {
    line-height: 3.9rem;
}

.header-top-menu {
    text-align: right;
}

.menu-button {
    font-size: 18px !important;
    font-family: '微软雅黑';
    font-style: normal;
    font-weight: 500 !important;
    text-decoration: none;
    color: #313b47 !important;
}

.space-item:hover {
    border-bottom: 0.14rem solid #1890ff;
    position: relative;
    height: 4rem;
    display: block;

}

.space-item-clicked {
    border-bottom: 0.14rem solid #1890ff;
    position: relative;
    height: 4rem;
    display: block;

}

.menu-button:hover {
    font-size: 18px !important;
    font-family: '微软雅黑';
    font-style: normal;
    color: #1890ff !important;
}

.menu-button-clicked {
    font-size: 18px !important;
    font-family: '微软雅黑';
    font-style: normal;
    color: #1890ff !important;
}
</style>